import React from 'react'

class packageSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      packageDropdown: '',
    }

    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  handleSelectChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    return (
      <div>
        {this.props.packageItem.ctaDropdownLabel}
        <form id="packageSelect">
          <select
            className="default_select mt-2"
            name="packageDropdown"
            id="packageDropdown"
            onChange={this.handleSelectChange}
            value={this.state.packageDropdown}
          >
            <option value="" disabled hidden selected>
              - select -
            </option>
            {this.props.packageItem.ctaDropdownEntries &&
              this.props.packageItem.ctaDropdownEntries.map((dropdownItem, index) => {
                return (
                  <option
                    key={`${index}_key`}
                    value={
                      'https://ows01.hireright.com/oseserv/entry?entry=cs_kiosk_signup&partner=' +
                      dropdownItem.ctaDropdownEntryPartnerId
                    }
                  >
                    {dropdownItem.ctaDropdownEntryName}
                  </option>
                )
              })}
          </select>
          <div className="mt-5">
            <a
              id="submit-button"
              className="bg-black button hover:bg-red text-white font-bold py-2 px-4 no-underline"
              type="button"
              {...(this.state.packageDropdown.length > 0 && { href: this.state.packageDropdown })}
            >
              {this.props.packageItem.CTAbuttonText}
            </a>
          </div>
        </form>
      </div>
    )
  }
}

export default packageSelect
