import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Microsite from '../components/microsites/index'
import SEO from '../components/seo'
import Layout from '../containers/micrositeLayout'
import 'typeface-montserrat'

export const query = graphql`
  query MicrositeTemplateQuery($id: String!) {
    microsite: sanityMicrosite(id: { eq: $id }) {
      _id
      _rawAboutUs
      _rawContactUs
      _rawWelcomeMessage(resolveReferences: { maxDepth: 5 })
      _rawGettingStartedMessage(resolveReferences: { maxDepth: 5 })
      _rawFaqs(resolveReferences: { maxDepth: 5 })
      _rawPackages(resolveReferences: { maxDepth: 5 })
      _rawPackagesSectionHeader
      _rawDownloadableFiles
      _rawResources
      downloadable_files {
        asset {
          url
          path
        }
        description
      }
      about_us {
        style
        _type
      }
      resources {
        style
        _type
      }
      coBrandingImage {
        alt
        asset {
          _id
          gatsbyImageData(layout: FIXED)
        }
      }
      mainBannerImage {
        asset {
          _id
          gatsbyImageData(layout: CONSTRAINED, width: 900)
        }
        alt
      }
      company_title
      text_banner_title
      text_banner_secondary
      contact_us {
        _type
        style
      }
      getting_started_message {
        style

        _type
      }
      welcome_message {
        _type
        style
      }
      title
      nav_options {
        standalonePages
        navPages
      }
      slug {
        current
      }
      faqs {
        _key
        _type
        title
        faqAnswer {
          _key
          _type
          style
        }
      }
      packages {
        CTAbuttonText
        CTAbuttonType
        _type
        title
        packageType
        packageDetails {
          _type
          style
        }
      }
      id
    }
  }
`

const MicrositeTemplate = (props) => {
  const { data, errors } = props
  const microsite = data && data.microsite
  return (
    <Layout {...microsite}>
      {errors && <SEO title="GraphQL Error" />}
      {microsite && <SEO title={microsite.company_title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {microsite && <Microsite {...microsite} />}
    </Layout>
  )
}

export default MicrositeTemplate
