import React from 'react'
import tw from 'tailwind.macro'
import BlockContent from '../block-content'
import PackageCtaButton from './packageCtaButton'
import PackageSelect from './packageSelect'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import FaqAccordion from './faqAccordion'

function Microsite(props) {
  const SectionWrapper = tw.div`
  md:flex mt-2
`
  const WelcomeSection = tw.section`
  flex-1 py-2
`
  const AboutSection = tw.section`
  flex-1 md:pr-24 mt-6 md:mt-6
`
  const ContactSection = tw.section`
  flex-1 mt-10 md:mt-6
`
  const FullWidthContainer = tw.div`
  w-full flex flex-col items-center
`
  const PageWidthContainer = tw.div`
  text-black max-w-7xl w-full font-display px-4
`
  return (
    <>
      <PageWidthContainer>
        <SectionWrapper className="py-8">
          {props._rawWelcomeMessage && (
            <WelcomeSection id="welcome section" className="welcome">
              <BlockContent blocks={props._rawWelcomeMessage || []} />
            </WelcomeSection>
          )}

          {props._rawGettingStartedMessage && (
            <WelcomeSection id="getting-started" className="getting-started">
              <BlockContent blocks={props._rawGettingStartedMessage || []} />
            </WelcomeSection>
          )}
        </SectionWrapper>
      </PageWidthContainer>

      {/* {props._rawPackagesSectionHeader &&
          <PageWidthContainer id="packages" class="mb-6">
             <BlockContent blocks={props._rawPackagesSectionHeader || []} />
          </PageWidthContainer>
         </FullWidthContainer>
        } */}

      {props?._rawPackages && !props?.nav_options?.standalonePages.includes('packages') && (
        <FullWidthContainer
          className={
            '' + (props._rawPackagesSectionHeader ? 'py-10 bg-hr-quartz' : 'py-2 bg-white')
          }
        >
          <PageWidthContainer id="packages">
            {props._rawPackagesSectionHeader && (
              <div className="pb-2">
                <BlockContent blocks={props._rawPackagesSectionHeader || []} />
              </div>
            )}
            <div className="flex-wrap mt-2 md:flex">
              {props._rawPackages.map((packageItem, index) => {
                return (
                  <div
                    key={`${index}_key`}
                    className={'flex md:pr-4 mb-6 ' + (index === 0 ? 'md:flex-1' : 'md:w-1/2')}
                  >
                    <div className="flex-1 bg-white shadow-mdFull">
                      <div className="package-card-title">
                        {packageItem.packageLogo && packageItem.packageLogo.asset && (
                          <img
                            width="40"
                            style={{ display: 'inline', 'margin-right': '20px' }}
                            className="inline pr-4"
                            src={imageUrlFor(buildImageObj(packageItem.packageLogo)).url()}
                          />
                        )}
                        {packageItem.title}
                      </div>
                      <div className="package-card-contents">
                        {packageItem.packageDetails && (
                          <div className="mb-6">
                            <BlockContent blocks={packageItem.packageDetails || []} />
                          </div>
                        )}

                        {packageItem.packageType == 'ctaDropdown' && (
                          <PackageSelect
                            packageItem={packageItem}
                            buttonText={packageItem.CTAbuttonText}
                          />
                        )}
                        {packageItem.packageType == 'ctaButton' && (
                          <PackageCtaButton
                            packageItem={packageItem}
                            buttonType={packageItem.CTAbuttonType}
                            buttonText={packageItem.CTAbuttonText}
                            packageId={packageItem.packageId}
                            packageCustomURL={packageItem.packageCustomId}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </PageWidthContainer>
        </FullWidthContainer>
      )}

      <PageWidthContainer>
        {props._rawFaqs && !props.nav_options.standalonePages.includes('faq') && (
          <div id="faq" className="py-10">
            <h2 className="pb-4">Frequently Asked Questions</h2>
            <FaqAccordion {...props}></FaqAccordion>
          </div>
        )}

        {props._rawDownloadableFiles && (
          <div id="downloadble-files" className="py-10">
            <h2 className="pb-4">Downloadable Files</h2>
            <div className="flex flex-col md:flex-row">
              {props.downloadable_files.map((downloadable_file, index) => {
                return (
                  <div key={`${index}_key`} className="w-full my-6 md:w-1/4 ">
                    <a
                      className="px-4 py-2 font-bold text-white no-underline bg-black button hover:bg-red"
                      href={downloadable_file.asset.url}
                    >
                      {downloadable_file.description}
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PageWidthContainer>

      {((props._rawAboutUs && !props.nav_options.standalonePages.includes('about_us')) ||
        (props._rawContactUs && !props.nav_options.standalonePages.includes('contact_us'))) && (
        <FullWidthContainer className="py-8 bg-hr-quartz">
          <PageWidthContainer>
            <div className="md:flex">
              {props._rawAboutUs && !props.nav_options.standalonePages.includes('about_us') && (
                <AboutSection className="about" id="about">
                  <h3>ABOUT HIRERIGHT</h3>
                  <BlockContent blocks={props._rawAboutUs || []} />
                </AboutSection>
              )}

              {props._rawContactUs && !props.nav_options.standalonePages.includes('contact_us') && (
                <ContactSection className="pb-4 contact" id="contact">
                  <h3>CONTACT HIRERIGHT</h3>
                  <BlockContent blocks={props._rawContactUs || []} />
                </ContactSection>
              )}
            </div>
          </PageWidthContainer>
        </FullWidthContainer>
      )}
    </>
  )
}

export default Microsite
