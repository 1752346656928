import React from 'react'

const PackageCtaButton = ({ buttonText, buttonType, packageCustomURL, packageId }) => {
  var urlPath = ''

  switch (buttonType) {
    case 'linkToLocalForm':
      urlPath = './form'
      break
    case 'linkToCustomURL':
      urlPath = packageCustomURL
      break
    default:
      //send to HR kiosk with customer id
      urlPath =
        'https://ows01.hireright.com/oseserv/entry?entry=cs_kiosk_signup&partner=' + packageId
      break
  }

  return (
    <div className="mb-2">
      <a
        className="bg-black button hover:bg-red text-white font-bold py-2 px-4 no-underline"
        href={urlPath}
      >
        {buttonText}
      </a>
    </div>
  )
}

// PackageCtaButton.defaultProps = {
//   };

// PackageCtaButton.propTypes = {
//     // buttonType: PropTypes.string,
//     buttonText: PropTypes.string.isRequired,
//     // packageId: PropTypes.string,
//     // packageCustomURL: string
//   };

export default PackageCtaButton
